@import '../../../../styles/variables.scss';

.mobileSearchMenu {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    z-index: 101;
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    transition: left 0.1s ease-in-out;
    top: 0;

    .header {
        display: flex;
        width: 100%;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding-top: 8px;
        padding-left: 16px;
        padding-right: 16px;
        column-gap: 20px;

        > h2 {
            font-size: 1.25rem;
            font-weight: 500;
        }

        .closeButton {
            border: 0;
            padding: 0;

            > svg {
                height: 40px;
                width: 40px;
            }
        }
    }

    .form {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;

        .searchInput {
            position: relative;
            width: 100%;

            > input {
                display: flex;
                padding-left: 52px;
                padding-right: 24px;
                width: 100%;
                height: 64px;
                border-radius: 12px;
                font-size: 1.125rem;
                color: $color-title-text;
                border: 1px solid $color-strokes-dark;
                transition: border-color linear 0.1s;

                &::placeholder {
                    color: $placeholdertext-grey;
                }

                &:hover {
                    border-color: $bodytext-darkgrey;
                }
                &:focus {
                    border-color: $tap-action-secondary;
                }
                &::placeholder {
                    color: $placeholdertext-grey;
                }
            }

            > label {
                display: flex;
                position: absolute;
                width: 28px;
                height: 28px;
                top: calc(50% - 14px);
                left: 16px;
                > svg {
                    width: 100%;
                    height: 100%;
                    fill: $color-brand;
                }
            }
        }
    }

    .results {
        width: 100%;
        display: flex;
        padding: 20px 16px;
        flex-flow: column nowrap;
        overflow-x: hidden;
        overflow-y: auto;
        row-gap: 8px;

        > h3 {
            margin: 0;
            color: $color-title-text;
            font-size: 1.25rem;
            font-weight: 500;
        }
        > ul {
            width: 100%;
            display: flex;
            flex-flow: column nowrap;
            padding: 0;
            margin: 0;
            list-style: none;
            row-gap: 8px;
        }
    }

    &[data-status='open'] {
        left: 0;
    }

    &[data-status='initial'],
    &[data-status='close'],
    &[data-status='unmounted'] {
        left: -100%;
    }
}
