@import '../../../styles/variables.scss';

.wrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.label {
    width: 100%;
    margin-bottom: 5px;
    min-height: 20px;
    span {
        padding-left: 3px;
        color: $amber-warning;
    }

    .subLabel {
        color: $bodytext-black;
        font-size: 14px;
        display: block;
        padding-left: 0;
        margin: 8px 0;
    }
}

.error {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: $warning-red;
    margin-top: 5px;
    min-height: 14px;
}
