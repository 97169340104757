@import '../../../styles/variables.scss';

.overlay {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    z-index: 1000;
    position: fixed;
    background-color: white;
    max-width: 100vw;
    inset: 0;
    overflow-y: auto;
    overflow-x: hidden;

    .closeButton {
        display: flex;
        position: fixed;
        padding: 0;
        right: 5px;
        top: 5px;
        box-shadow: none;
        width: fit-content;
        height: fit-content;
        border: 0px;
        background: transparent;
        z-index: 1;
        cursor: pointer;
        transition: all 0.15s ease;

        &::before {
            display: flex;
            position: absolute;
            content: '';
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background-color: #000;
            left: 1px;
            top: 1px;
            border-radius: 50%;
            z-index: -1;
        }

        svg {
            fill: #fff;
            border-radius: 50%;
        }

        &:hover::before {
            background-color: $color-brand;
        }
    }

    @include respond-to('small') {
        inset: initial;
        top: 80px;
        max-height: calc(100vh - 160px);
        width: 600px;
        left: calc((100vw - 600px) / 2);
        border-radius: 20px;
        margin-left: auto;

        .closeButton {
            right: calc(((100vw - 600px) / 2) + 20px);
            top: 90px;
        }
    }

    @include respond-to('medium') {
        top: 40px;
        max-height: calc(100vh - 80px);
        width: 800px;
        left: calc((100vw - 800px) / 2);

        .closeButton {
            right: calc(((100vw - 800px) / 2) + 20px);
            top: 50px;
        }
    }
}

body {
    &.hideWidgets {
        :global(#chat-widget-container),
        :global(#responseiq-app-container) {
            display: none;
        }
    }
}
