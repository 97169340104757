@import '../../../styles/variables.scss';

$margin: 20px;
.overlayedModal {
    border-radius: 20px;
    margin: auto;
    height: 720px;
    width: calc(100vw - (2 * #{$margin}));
    max-height: calc(100vh - (2 * #{$margin}));

    :global(.modal-closeButton) {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    @include respond-to('small') {
        max-height: calc(100vh - 100px);
        margin-left: auto;
        width: 600px;
    }

    @include respond-to('medium') {
        width: 800px;
    }
}
