@import '../../../../../styles/variables.scss';

$mobileHeight: var(--mobile-height);

.menuItem {
    flex: 0 0 48px;
}

.subMenu {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    z-index: 102;
    width: 100vw;
    height: calc(100vh - #{$mobileHeight});
    overflow-y: auto;
    overflow-x: hidden;
    transition: left 0.1s ease-in-out;
    top: $mobileHeight;
    position: absolute;
    padding: 16px 52px;

    .backButton {
        display: flex;
        height: 48px;
        flex: 0 0 48px;
        margin-right: auto;
        padding: 0 32px;
        border: 1px solid $color-brand;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        font-size: 1.125rem;
        font-weight: 600;
        color: $color-title-text;
    }

    &[data-status='open'] {
        left: 0;
    }

    &[data-status='initial'],
    &[data-status='close'],
    &[data-status='unmounted'] {
        left: -100%;
    }
}
