@import '../../../styles/variables.scss';

.textInput {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    align-content: center;
    outline: 0;
    border-radius: 3px;
    background-color: $white;
    border: solid 1px $light-grey;
    height: 40px;
    padding: 10px;
    transition: border-color linear 0.1s;
    color: $bodytext-black;

    &:hover {
        border-color: $bodytext-darkgrey;
    }
    &:focus {
        border-color: $tap-action-secondary;
    }
    &::placeholder {
        color: $placeholdertext-grey;
    }

    &.hasIcon {
        padding-left: 52px;
        background-repeat: no-repeat;
        background-size: 30px 30px;
        background-position: 12px center;
    }

    &[disabled] {
        background-color: $color-background-disabled;
        border-color: $light-grey;
        color: $color-strokes-disabled;
    }
}
