@import '../../../styles/variables.scss';

.error {
    border: 1px solid $amber-warning;
    background-color: lighten($amber-warning, 40);
    border-radius: 5px;
    padding: 20px 10px;
    box-sizing: border-box;

    a {
        color: $bodytext-black;
    }
}
