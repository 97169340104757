@import '../../styles/variables.scss';

.signup {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 20px;
    padding: 0 10px 10px 10px;
    align-items: center;
    justify-content: flex-start;

    .content {
        padding: 5px;
        > h2 {
            text-align: center;
            margin-top: 0;
        }
        .message {
            text-align: center;
        }
    }

    @include respond-to('medium') {
        display: flex;
        flex-flow: row-reverse nowrap;
        align-items: flex-start;
        padding: 0;

        .content,
        .image {
            flex: 0 0 50%;
        }

        .content {
            padding: 32px 20px;

            > h2 {
                font-size: 1.5rem;
            }
        }

        .image {
            height: 100%;
            overflow: hidden;

            > img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        @media screen and (min-height: 700px) {
            .content {
                > h2 {
                    font-size: 2.5rem;
                }
            }
        }
    }
}
