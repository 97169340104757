@import '../../../../../../styles/variables.scss';

$desktopHeaderHeight: var(--desktop-height);

.floatingMenu {
    display: none;
    z-index: 101;
    position: absolute;
    top: calc(#{$desktopHeaderHeight} + 2px);
    transform: translateX(calc((100vw - 100%) / 2));
    flex-flow: row nowrap;
    border: 1px solid $color-strokes;
    padding: 40px;
    width: 90vw;
    max-width: 1200px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow:
        0px 16px 32px -4px rgba(62, 52, 69, 0.1),
        0px 2px 4px 0px rgba(62, 52, 69, 0.25);
    transition: opacity 0.1s linear;

    .menu {
        display: flex;
        flex-flow: column nowrap;
        flex: 0 0 calc(100% / 3);
        border-right: 1px solid $color-strokes;
        padding-right: 64px;
        row-gap: 4px;
    }

    .submenu {
        padding-left: 64px;
        flex: 1 1 auto;
    }

    &.hasOnlyLinks {
        width: 75vw;
        max-width: 800px;
        .menu {
            flex-basis: calc(50%);
        }
    }

    &[data-status='open'] {
        opacity: 1;
    }

    &[data-status='initial'],
    &[data-status='close'],
    &[data-status='unmounted'] {
        opacity: 0;
    }

    @include respond-to('medium') {
        display: flex;
    }
}
